import Navbar from "../components/Navbar"
import Workcard from "../components/MainPageComponents/Workcard.js"
import More from "../components/MainPageComponents/More.js"
import Bottom from "../components/Bottom.js"
import Awards from "../components/MainPageComponents/Awards.js"

function Projects() {
  return (
    <>
    <Navbar location='Work' />

    <div className="spacerM mnone"></div>
    <div className="monly" style={{height:'48px',width:'100%'}}></div>

    <div className="row justify-content-center">
      <div className="row bound1" style={{width:'100%'}}>
        <Workcard
            title="Software Engineer Intern"
            time="Fall 2022"
            company="Rippling"
            description="Automating prevention and mitigation against risky and fraudulent incidents on the Risk Engineering Team"
            tools="Python, Django, MongoDB, React.js"
            linkPath="https://www.rippling.com/"
        />
          <Workcard
            title="Software Engineer Intern"
            time="Summer 2022"
            company="Addepar"
            description="Designed and built data pipelines for private securities, and heavily optimized fuzzy matching algorithms"
            tools="Databricks, Apache Spark, Python, Java"
            linkPath="Addepar"
          />
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="row bound1" style={{width:'100%'}}>
        <Workcard
            title="Project Manager Intern"
            time="Summer 2021"
            company="Interactive Media Lab"
            description="Managing software/hardware projects combating functional decline in aging populations at the University of Toronto"
            tools="React.js,Trello"
            linkPath="https://imedia.mie.utoronto.ca/"
        />
        <Workcard
          title="Software Engineer Intern"
          time="Winter 2021"
          company="Index Exchange"
          description="Lead development of a new centralized deployment UI, automation pipelines, and also standardized development processes"
          tools="Kubernetes,Ansible,Docker,Helm,GitLab"
          linkPath="https://www.indexexchange.com/"
        />
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="row bound1" style={{width:'100%'}}>
        <Workcard
          title="Web Development Intern"
          time="Fall 2020"
          company="MediPaint / Venture for Canada"
          description="Redesigned the website improving user navigation, information architecture, aesthetics, and also lead online presence discussions."
          tools="Figma,Wordpress,Elementor"
          linkPath="https://ventureforcanada.ca/programs/internship-program"
        />
        <Workcard
          title="Software Freelancer"
          time="All of 2020"
          company="FarmBot"
          description="Contributed to using OCR for smart plant recognition. Wrote documentation for a new education initiative and for the open source community"
          tools="Python,Pytorch,Jetson Nano"
          linkPath="https://farm.bot/"
        />
        {/* <More /> */}
      </div>
    </div>
    <div className="spacerM"></div>

    <div className="row mx-4 justify-content-center">
        <Awards
            awards="
            2021|
            RU Hacks 2021 Winner-Top 5 overall-Wolfram Alpha award-The Codex award,
            HooHacks 2021 Winner-Best use of Google cloud,
            nwHacks 2021 Honorable Mention-Codex Pro Account
            |2020|
            Hack Western 2020 Winner-Best hardware hack,
            Deans List-Awarded for academic excellence,
            Hack The North East 2020 Mention-Top 10 machine learning hacks-Top 10 most viable startup hacks,
            RU Hacks 2020 Winner-2nd overall-Best Use of Google Cloud-Wolfram Alpha award
            |2019|
            Alexander Rutherford Scholarship,
            University of Toronto Entrance Scholarship,
            Best Delegate University of Chicago MUN"
        />
    </div>

    <div className="spacerL"></div>

    <Bottom />
    </>
  );
}

export default Projects;
