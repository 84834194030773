const More = (props) => {
    return (
    <>
    <div className="col-sm-6 nopad" style={{paddingLeft:'26px',paddingRight:'26px'}}>
        <a className="aa" href={props.linkPath} target="_blank">
        <div className="card nopad" style={{width:'100%'}}>
            <div className="card-body smallBound nopad ">
                <div className="row align-items-start h384" style={{paddingRight:'10%',paddingLeft:'10%'}}>
                        <div className="row" style={{width:'100%', height:'100%',paddingBottom:"12px"}}>
                            <div className="col my-auto">
                                <div style={{height:'48px',width:'100%'}}></div>
                                <h2 class="text-center">Hopefully more to come!</h2>
                                <div style={{height:'24px',width:'100%'}}></div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        </a>
        <div style={{height:'48px',width:'100%'}}></div>
    </div>
    </>
    )
}

export default More
