import Navbar from "../components/Navbar"
import Intro from "../components/MainPageComponents/Intro"
import Largecard from "../components/MainPageComponents/Largecard.js"
import Smallcard from "../components/MainPageComponents/Smallcard.js"
import Sayhi from "../components/MainPageComponents/Sayhi.js"
import Bottom from "../components/Bottom.js"

function Projects() {
  
  
  {/* Slider Function */}
  let isOn = false
  function removeImages(){
    if(isOn){
      let elem= document.getElementsByClassName("cardImg");
      for (let i = 0; i < elem.length; i++) {
        elem[i].style.display = 'block';
      }
      let e=document.getElementsByClassName("changecol");
      e[0].style.color="#9E9E9E"
      isOn=false
    }
    else{
      let elem = document.getElementsByClassName("cardImg");
      for (let i = 0; i < elem.length; i++) {
        elem[i].style.display = 'none';
      }
      let e=document.getElementsByClassName("changecol");
      e[0].style.color="#651FFF"
      isOn=true
    }
  }

  return (
    <>
    <Navbar location='Projects' />

    <Intro />
    {/* This container holds all project cards */}
    <div className="container-fluid">

      {/* Slider */}
      <div className="row justify-content-center">
        <div className="row mx-4 bound" style={{width:"100%"}}>
          <div className="row bound box ml-auto">
            <div className="col-xs-* nopad" style={{padding:"4px 6px 4px 12px"}}>
              <p className="nopad mobileuniq"><b className="changecol" style={{color:"#9E9E9E"}}>No Image</b></p>
            </div>
            <div className="col-xs-* nopad" style={{padding:"4px 14px 4px 6px"}}>
              <label class="switch nopad">
                <input type="checkbox" onClick={removeImages}/>
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div style={{height:'32px',width:'100%'}    }></div>
      </div>
      
      {/* Smaller Showcase Cards */}
      <div className="row justify-content-center">
        <div className="row">
          <Smallcard
            status="// Personal Project - Ongoing"
            title="Pilly"
            description="Pill tracking application designed for those who need to manage medications for the elderly or the young"
            languages="Dart"
            tools="Flutter,Figma,Adobe Illustrator"
            imageName='mpilly.png'
          />
          <Smallcard
            status="// Personal Project"
            title="Steg Hide"
            description="Open source program for hiding and transporting information to areas of the world with censorship on knowledge"
            languages="C"
            tools="Steganography,Image Processing"
            imageName='msteghide.png'
          />
        </div>
        <div style={{height:'0px',width:'100%'}}></div>

        <div className="row">
          <Smallcard
            status="// HooHacks 2021 - Winner"
            title="EcoBlock"
            description="Sustainable & scalable POS blockchain and wallet with innovative green minting incentives"
            languages="Dart,Typescript,Python"
            tools="Node.js,Flutter,OCR,Firebase,Figma"
            imageName='mecoblock.png'
          />
          <Smallcard
            status="// RU Hacks 2021 - Winner"
            title="Botanist"
            description="NLP powered no-code Discord Bot generator with a community expandable command library"
            languages="Javascript,Shell"
            tools="Discord.js,Figma,Google NLP"
            imageName='mbotanist.png'
          />
        </div>
        <div style={{height:'0px',width:'100%'}}></div>

        <div className="row">
          <Smallcard
            status="// HTNE 2020"
            title="Vibe Check"
            description="Find the mood of your Spotify playlist and filter out songs that don’t match the mood"
            languages="Javascript,HTML5,CSS3"
            tools="Brain.js,Node.js,Spotify API,Heroku"
            imageName='mvibecheck.png'
          />
          <Smallcard
            status="// Hack Western 7 - Winner"
            title="Certi-Chain"
            description="Blockchain powered solution to prevent the counterfeiting of products"
            languages="Python,HTML5,CSS3"
            tools="Flask,Jinja,Heroku"
            imageName='mcertichain.png'
          />
        </div>
        <div style={{height:'0px',width:'100%'}}></div>

        <div className="row">
         <Smallcard
            status="// nwHacks - Honorable Mention"
            title="Vaccurate"
            description="Data powered website for organizing COVID-19 vaccine distribution"
            languages="JavaScript,HTML5,CSS3"
            tools="Twilio,Figma,Firestore,Bootstrap"
            imageName='mvaccurate.png'
          />
          <Smallcard
            status="// BackyardHacks"
            title="Planty"
            description="Chrome extension that suggest ingredient substitutes with lower carbon footprints for recipes"
            languages="Javascript,HTML5,CSS3"
            tools="Firebase,Browserify"
            imageName='mplanty.png'
          />
      </div>
      <div style={{height:'0px',width:'100%'}}></div>

      <div className="row">
          <Smallcard
            status="// RU Hacks 2020 - Winner"
            title="Farmily"
            description="Connect farmers with excess produce to local consumers to reduce food waste due to COVID-19"
            languages="Javascript,HTML5,CSS3"
            tools="Firebase,Google Maps API, Node.js"
            imageName='mfarmily.png'
          />
          <Smallcard
            status="// Personal Project"
            title="This Website"
            description="A collection of my projects, work experience, and info about me"
            languages="Javascript,HTML5,CSS3"
            tools="React.js,Bootstrap,Figma"
            imageName='mwebsite.png'
          />
      </div>
      <div style={{height:'0px',width:'100%'}}></div>
      <div className="row">
          <Smallcard
            status="// Hack Calgary 2021"
            title="DormBuddy"
            description="Virtual video calling app to emulate social interactions between students in dorms"
            languages="Typescript,Javascript,HTML5,CSS3"
            tools="Vue.js,Node.js,Bulma,Bootstrap,Firebase,Figma"
            imageName='mdormbuddy.png'
          />
          <Smallcard
            status="// School Project"
            title="Grocery Route"
            description="Find items in a store with ease and plan a route to get everything on your grocery list"
            languages="Dart, Python, SQL"
            tools="Flutter,SQLite,Django,Figma"
            imageName='mgroceryroute.png'
          />
      </div>
      <div style={{height:'0px',width:'100%'}}></div>

      <Sayhi />

      <Bottom />
      </div>
    </div>
    </>
  );
}

export default Projects;
