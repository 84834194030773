import React, { useEffect, useState } from 'react';

const Intro = () => {
    // TODO: FIX THIS REALLY BAD CODE
    let intro = []
    const words = "M Y I N A M E g o L I K E t o c s s a m c r e a t i n g i s t h e h t m l s o f t w a r e u n i v e r s i t y a c o o l j o h n s o n e n g i n e e r o f d e v o p s t h i n g s ! r e a c t t o r o n t o s u p"
    let separray = words.split(" ")
    let counter = 0

    function makeacol(seperatedArray){
        let newcol= [];
        for (let i=0; i<seperatedArray.length; i++){
            newcol.push(<div className="col"><h2 className="introwords" id={counter} style={{color:"#EDE7F6"}}>{seperatedArray[i].toUpperCase()}</h2></div>)
            counter=counter+1;
        }
        return newcol;
    }

    let i=0,j,temparray,chunk = 15;

    for (i,j=separray.length; i<j; i+=chunk) {
        temparray = separray.slice(i,i+chunk);
        let tempcol = makeacol(temparray)
        intro.push(
            <div className="row" style={{width:"100%"}}>
                {tempcol}
            </div>
        )
    }

    useEffect(() => {  
        startcycle();
    }, [])

    return (
        <div className="container-fluid nopad">
            <div className="row mx-4 justify-content-center screen60">
                <div className="spacerS" style={{width:'100%'}}></div>
                <div className="row bound my-auto">
                    {intro}
                </div>
                <div className="spacerS" style={{width:'100%'}}></div>
            </div>
        </div>
    )
}

export default Intro

const my=[0,1], i=[2], namee= [3,4,5,6], like=[9,10,11,12], go=[7,8], am=[18,19]
const to=[14,13], creating=[20,21,22,23,24,25,26,27], the=[30,31,32], is=[28,29]
const a=[23], cool=[59,58,57,56], software=[37,38,39,40,41,42,43,44], university=[45,46,47,48,49,50,51,52,53,54]
const johnson=[60,61,62,63,64,65,66], engineer=[67,68,69,70,71,72,73,74], of=[75,76]
const things=[83,84,85,86,87,88,89], toronto=[95,96,97,98,99,100,101], su=[102,103]

let one = [].concat(my,namee,is,johnson,su)
let two = [].concat(i,am,a,software,engineer)
let three = [].concat(i,go,to,the,university,of,toronto)
let four = [].concat(i,like,creating,cool,things)

let running = false;
async function startcycle(){
    if (running === false){
        running=true;
        let c=0;
        while (c<4){
            if(c===0){
                setcolor(one);
            } else if (c===1){
                setcolor(two);
            } else if (c===2){
                setcolor(three)
            } else{
                setcolor(four);
            }
            await new Promise(resolve => setTimeout(resolve, 4300));
            c=c+1;
            if(c===4){
            c=0;
        }
    }
    }
}

async function setcolor(senarray){
    let hold = senarray
    for (let j=0; j<hold.length; j++){
        let test = document.getElementById(hold[j]);
        if (test == null) return
        test.style.color="#651FFF"
    }
    await new Promise(resolve => setTimeout(resolve, 4000));
    for (let j=0; j<hold.length; j++){
        let test = document.getElementById(hold[j]);
        if (test == null) return
        test.style.color="#EDE7F6"
    }
}
