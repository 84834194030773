const Workcard = (props) => {
    //pill generation
    let toolArray = props.tools.split(',');
    let tools = [];
    for (let i=0; i<toolArray.length; i++){
        if (i===0){
            tools.push(<div className="first secondaryPillsm" >{toolArray[i]}</div>);
        } else if (i===toolArray.length){
            tools.push(<div className="secondaryPillsm">{toolArray[i]}</div>);
        } else {
            tools.push(<div className="last secondaryPillsm">{toolArray[i]}</div>);
        }
    }

    return (
    <>
    <div className="col-sm-6 nopad" style={{paddingLeft:'26px',paddingRight:'26px'}}>
        <a className="aa" href={props.linkPath} target="_blank">
        <div className="card nopad hovercard">
            <div className="card-body smallBound nopad ">
                <div className="row align-items-start h384" style={{paddingRight:'10%',paddingLeft:'10%'}}>
                        <div style={{height:'42px',width:'100%'}}></div>
                        <div className="row" style={{width:'100%',paddingBottom:"12px"}}>
                            <p className="nopad">
                            {props.time}
                            <div style={{height:'0px',width:'100%'}}></div>
                            <b>{props.company}</b>
                            <div style={{height:'16px',width:'100%'}}></div>
                            <h3><b style={{color:'black'}}>{props.title}</b></h3>
                            <div style={{height:'12px',width:'100%'}}></div>
                            <p>{props.description}</p>
                            <div style={{height:'4px',width:'100%'}}></div>
                            {tools}
                            </p>
                        </div>
                        <div style={{height:'32px',width:'100%'}}></div>
                </div>
            </div>
        </div>
        </a>
        <div style={{height:'48px',width:'100%'}}></div>
    </div>
    </>
    )
}

export default Workcard
