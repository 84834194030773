import Navbar from "../components/Navbar"
import Bottom from "../components/Bottom.js"

import Workdesc from "../components/ProjectPageComponents/Workdesc"
import Overview from "../components/ProjectPageComponents/Overview.js"
import GenericText from "../components/ProjectPageComponents/GenericText.js"
import ImageWCaption from "../components/ProjectPageComponents/ImageWCaption.js"


function Addepar() {
  return (
    <>
    <Navbar/>
    <div className="spacerM mnone"></div>
    <div style={{height:'32px',width:'100%'}}></div>

    <Overview
        status="Summer 2022 Work Term"
        title="Addepar"
        desc="A wealth management platform that uses data and analytics to bring sense to the most complex investment portfolios"
        copyright=""
        links={["Website|https://addepar.com/"]}
        image="maddepar.png"
        style={{backgroundColor: 'black', borderRadius: '12px'}}
    />

    <div className="spacerM"></div>

    <Workdesc
        problem="Software Engineer Intern"
        solution="Create a way to accurately classify private securities in Databricks in a performant, scalable, and customizable way"
        tools="Databricks, Apache Spark, Python, Java"
        team="Global Security Master"
        duration="4 Months"
        optionname="Field"
        optioncontent="FinTech"
    />

<div className="spacerM"></div>

<GenericText
    title="The Work Outlined"
    content={
        <>
        <p>
          To properly classify information the first step is to get the information in the first place. When this information is received it is an a raw form,
          so it needs to be standardized and cleaned so that inconsistencies and junk data can be removed. This is done through the medallion architecture.
          Firsly, data is loaded in raw into a bronze table. From the bronze table it is transformed and cleaned and then stored into a silver table. These silver tables 
          contain all known entities given from the data. Lastly, the information is cleaned and filtered into a final business logic ready table that can be used to 
          service various services such as API's.
        </p>
        </>
    }
/>

<ImageWCaption
    title=""
    caption={["Image of the medallion architecture"]}
    image={["medallion.jpeg"]}
/>

<GenericText
    title=""
    content={
        <>
        <p>
          The finished data is ready to use now and can be used in a pratical application. In this case the data will be used for a fuzzy matching algorithm
          (weighted jaccard similarity algorithm). In essence the algorithm will match a "target word" against a "candidate word" and determine a similarity 
          score between the two. Private securities don't have any industry standard for identification so string matching will often result in the most consistent results.
          However, running the algorithm at a massive scale such as having 100k candidates matched agaisnt 2 million targets makes the problem much harder. This would 
          product 260 billion rows of data to run the algorithm on which is just not feasible. So lots of work was done to optimize the process. From optimizing joins,
          filtering data, serialization and more the final algorithm ran within the time bounds and is headed to production.
        </p>
        </>
    }
/>

<div className="spacerM"></div>

<GenericText
        title="The Big Results"
        content={
            <>
            <p>
              After optimizing the algorithm the runtime went from being unrunnable in the cluster to 6 hours to 9 minutes leading to a 4000% runtime improvement.
              Aditionally, after cleaning and storing the data it went from 255 GB of data to 3 GB of highly refined data again saving storage and computation!
            </p>
            </>
        }
    />

<div className="spacerM"></div>

<GenericText
    title="Reflection"
    content={
        <>
        <p>
          This work term taught me so much about working with everything realted to scale. First there was the scale of big data where I learnt platforms and frameworks
          that allowed me to tame large quantities of data. Secondly I was able to work with the scale of large codebases where I had to refine my coding practices,
          documentation, and follow style guides to ensure my work was usuable to everyone else. Lastly, I was able to work in a large scaled company where I had to know 
          how my project contributed to the company goals to let me prioritize features. Working with this unique data problem also gave me great insights into the realm of
          data engineering.
        </p>
        <div style={{height:'24px',width:'100%'}}></div>
        <p>
          Lastly, this internship played an important part in my career path as it allowed me to experience yet another catagory of software engineering
          and also taught me how to work in a mid-sized company. Overall, I've been very satisfied by my experience at Addepar!
        </p>
        </>
    }
/>

    <div className="spacerL"></div>
    <Bottom/>
    </>
  );
}

export default Addepar;
