import Navbar from "../../components/Navbar"
import Bottom from "../../components/Bottom.js"

import Probsol from "../../components/ProjectPageComponents/Probsol.js"
import Overview from "../../components/ProjectPageComponents/Overview.js"
import UnderConstruction from "../../components/ProjectPageComponents/UnderConstruction.js"


function GroceryRoute() {
  return (
    <>
    <Navbar/>
    <div className="spacerM mnone"></div>
    <div style={{height:'32px',width:'100%'}}></div>

    <Overview
        status="// School Project"
        title="Grocery Route"
        desc="Find items in a store with ease and plan a route to get everything on your grocery list"
        copyright=""
        links={["Contact Me For Details|mailto: johnsonn.su@mail.utoronto.ca"]}
        image="mgroceryroute.png"
        style={{backgroundColor: '#FEF2D3', borderRadius: '12px'}}
    />

    <div className="spacerM"></div>

    <Probsol
        problem="I want to know exactly where an item is in a store and how to get to it"
        solution="Develop an app that allows users to find specific products, and plan route within the store for groceries"
        tools="Dart, Python, SQL, Flutter, SQLite, Django, Figma"
        team="Haowen Rui, Chang Liu, Raymond Ho, Nimra Maqbool, Hrithik Kumar Advani, Long Hei Mak"
        duration="4 months"
        optionname="Topic"
        optioncontent="Food and Convenience"
    />

    <div className="spacerL"></div>

    <UnderConstruction/>

    <div className="spacerL"></div>
    <Bottom/>
    </>
  );
}

export default GroceryRoute;