import Navbar from "../../components/Navbar"
import Bottom from "../../components/Bottom.js"

import Probsol from "../../components/ProjectPageComponents/Probsol.js"
import Overview from "../../components/ProjectPageComponents/Overview.js"
import GenericText from "../../components/ProjectPageComponents/GenericText.js"
import DoubleImages from "../../components/ProjectPageComponents/DoubleImages.js"


function VibeCheck() {
  return (
    <>
    <Navbar/>
    <div className="spacerM mnone"></div>
    <div style={{height:'32px',width:'100%'}}></div>

    <Overview
        status="Hackathon Project"
        title="Vibe Check"
        desc="Find the mood of your Spotify playlist and filter out songs that don’t match the mood"
        copyright=""
        links={["Devpost|https://devpost.com/software/vibe-check-q93jaz","GitHub|https://github.com/Johnson-Su/vibe-check","Website|https://vibe-check-htne.herokuapp.com/"]}
        image="mvibecheck.png"
        style={{backgroundColor: '#F26738', borderRadius: '12px'}}
    />

    <div className="spacerM"></div>

    <Probsol
        problem="I want to keep the vibe going when I’m listening to music and don’t want songs which breaks my mood"
        solution="Allow people to identify the genre or “vibe” of their playlists and let them easily remove songs that could break the mood"
        tools="Javascript, HTML5, CSS3, Brain.js, Heroku, Node.js, Spotify Web API, Illustrator, Figma"
        team="Amy Han, Andrew Yu"
        duration="36h"
        optionname="Topic"
        optioncontent="Music & Data"
    />

    <div className="spacerM"></div>

    <GenericText
        title="What it does"
        content={
            <>
            <p>
              Vibe Check allows Spotify users to <b>curate existing playlists to maintain a consistent “vibe”</b> throughout the playlist. 
              Users can <b>access their own playlists as well as search public playlists</b> to find playlists with the right overall vibe. 
              Once they’ve found a suitable playlist, users can set the sensitivity of the vibe checker to generate a new, curated playlist, <b>removing tracks that stray too far from the overall vibe</b> of the original playlist. 
              Additionally Vibe Check has a <b>trained a neural network </b>that is able to classify the “vibe” of the playlist through over 1000+ songs of training data.
            </p>
            </>
        }
    />

    <div className="spacerM"></div>

    <DoubleImages
            title="Feature Pictures"
            captions={["Intro page to Vibe Check",
            "Spotify auth login to access user info"]}
            images={["VibeCheck/vibecheck1.png","VibeCheck/vibecheck2.png"]}
        />

        <DoubleImages
            title=""
            captions={["Browsing your own playlists and public search",
            "Playlist statistics and newly made playlist"]}
            images={["VibeCheck/vibecheck4.png","VibeCheck/vibecheck3.png"]}
        />

        <div className="spacerM"></div>

        <GenericText
            title="Technical Details"
            content={
                <>
                  <p>
                    Vibe Check was made with <b>HTML</b> and <b>CSS</b> and used <b>Bootstrap</b> as it's framework. 
                    It uses <b>Node.js</b> for it's backend to access the <b>Spotify API</b> and process the song data pulled.
                    Several fields of the song data such as danceability and energy are taken into account when deciding which songs to remove (they are removed based on their <b>Z-score</b>).
                    The machine learning portion was done with <b>Brain.js</b> and was trained with the song data for over 1000 songs over multiple genres.
                    It was prototyped with <b>Figma</b> and hosted on <b>Heroku</b>.
                  </p>
                </>
            }
        />

        <div className="spacerM"></div>

        <GenericText
            title="Reflection"
            content={
                <>
                <p>
                Personally, I had to juggle several roles at the same time while <b>learning new concepts and immediately applying them</b> making me a much more <b>flexible individual</b>.
                 There were times of frustration and times of happiness as the project moved forward. I was <b>excited to learn</b> so much and it really opened my eyes to what code can do. 
                 The satisfaction of running my neural network and having it predict the song after hours of work through the night was incredibly reliving.
                </p>
                <div style={{height:'24px',width:'100%'}}></div>
                <p>
                Finally, I’m glad that my work was able to make a difference to others. 
                After hearing that others used my program and enjoyed it’s use it really made me realize that much of my joy in creating projects not only comes from satisfying my desire to learn but to also see that I could <b>benefit others</b> with my work!
                </p>
                </>
            }
        />

        <div className="spacerL"></div>
        <Bottom/>
        </>
  );
}

export default VibeCheck;
