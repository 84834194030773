import Navbar from "../../components/Navbar"
import Bottom from "../../components/Bottom.js"

import Probsol from "../../components/ProjectPageComponents/Probsol.js"
import Overview from "../../components/ProjectPageComponents/Overview.js"
import GenericText from "../../components/ProjectPageComponents/GenericText.js"
import DoubleImages from "../../components/ProjectPageComponents/DoubleImages.js"


function CertiChain() {
  return (
    <>
    <Navbar/>
    <div className="spacerM mnone"></div>
    <div style={{height:'32px',width:'100%'}}></div>

    <Overview
        status="Hackathon Winner"
        title="Certi-Chain"
        desc="Blockchain powered solution to prevent the counterfeiting of products"
        copyright=""
        links={["Devpost|https://devpost.com/software/certi-chain","GitHub|https://github.com/Johnson-Su/Certi-Chain"]}
        image="mcertichain.png"
        style={{backgroundColor: '#D4D4D4', borderRadius: '12px'}}
    />

    <div className="spacerM"></div>

    <Probsol
        problem="How can I know that the product I'm buying is authentic?"
        solution="Attach a unique id onto the product that cannot be faked on the product using blockchain"
        tools="Python, Flask, HTML5, CSS3, NFC, Figma"
        team="Jielin Xia, Vincent Li, Benjamin Saobuppha"
        duration="36h"
        optionname="Topic"
        optioncontent="Blockchain"
    />

<div className="spacerM"></div>

<GenericText
    title="What it does"
    content={
        <>
        <p>
          Certi-Chain uses a <b>python based blockchain</b> to authenticate any products with a Certi-Chain <b>NFC tag</b>. 
          Each tag will contain a unique ID attached to the blockchain that <b>cannot be faked</b>. 
          Users are able to tap their phones on any product containing a Certi-Chain tag to view the authenticity of a product through the Certi-Chain blockchain. 
          Additionally if the product is authentic users are also able to <b>see where the products materials were sourced and assembled</b>.
        </p>
        </>
    }
/>

<div className="spacerM"></div>

<DoubleImages
        title="Feature Pictures"
        captions={["App UI Screenshots",
        "Certi-Chain tags on products example"]}
        images={["Certi-Chain/certichain1.png","Certi-Chain/certichain2.png"]}
    />

    <DoubleImages
        title=""
        captions={["Company side product portal",
        "More example tags for potential uses"]}
        images={["Certi-Chain/certichain3.png","Certi-Chain/certichain4.png"]}
    />

    <div className="spacerM"></div>

    <GenericText
        title="Technical Details"
        content={
            <>
              <p>
                Certi-Chain uses a <b>simple python blockchain implementation</b> to store the relevant product data. 
                It uses a <b>proof of work</b> algorithm to add blocks to the blockchain and check if a blockchain is valid. 
                Additionally, since this blockchain is decentralized, nodes (computers that host a blockchain) have to be synced using a <b>consensus algorithm</b> to decide which version of the blockchain from any node should be used. 
                In order to render web pages, we used <b>Python Flask</b> with our web server running the blockchain to fetch relative information from the blockchain and displayed it to the user in a style that is easy to understand. 
                A <b>web client</b> to input information into the chain was also created using Flask to <b>communicate with the server</b>.
              </p>
            </>
        }
    />

    <div className="spacerM"></div>

    <GenericText
        title="Reflection"
        content={
            <>
            <p>
              This project was really exciting as it was my first real exposure to working with Blockchain technology and it was especially hands on.
              I got to go from just a concept to actually tapping a product to test its authenticity in just 36h!
              I have <b>high hopes for the future of this project</b> and hope to work on this further as I really <b>believe that blockchain will disrupt the counterfeiting industry</b>. 
            </p>
            <div style={{height:'24px',width:'100%'}}></div>
            <p>
              I was able to learn lots of technical skills this time around as I had never worked with <b>Flask</b> before or any sort of <b>blockchain</b>.
              I'm really glad about how much I got to learn and hope to further develop my skills with projects as interesting as this one! [ And maybe even try and <b>aim for a startup :)</b> ]
            </p>
            </>
        }
    />

    <div className="spacerL"></div>
    <Bottom/>
    </>
  );
}

export default CertiChain;
