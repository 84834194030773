const UnderConstruction= (props) => {
    return (
    <>
    <div className="row mx-4">
        <div className="row bound mx-auto">
            <h2>This Page Is Under Construction!</h2>
            <div style={{height:'24px',width:'100%'}}></div>
        </div>
    </div>
    </>
    )
}

export default UnderConstruction
