import Navbar from "../components/Navbar"
import Aboutme from "../components/MainPageComponents/Aboutme.js"
import Bottom from "../components/Bottom.js"

function TLDR() {

    let toolz = "Kubernetes,Docker,Ansible,Helm,GitLab,Figma,React.js,Node.js,Bootstrap,Git,Figma,Scikit-learn"
    let languagez = "Python,C,Java,Javascript,HTML,CSS,SQL"
    let toolArray = toolz.split(',');
    let languageArray = languagez.split(',');
    let tools = [];
    let languages = [];
    for (let i=0; i<toolArray.length; i++){
        tools.push(<div className="secondaryPillsm" style={{backgroundColor:"#F6F6F6"}}>{toolArray[i]}</div>);
    }
    for (let i=0; i<languageArray.length; i++){
        languages.push(<div className="secondaryPillsm" style={{backgroundColor:"#F6F6F6"}}>{languageArray[i]}</div>);
    }

  return (
    <>
    <Navbar location='TLDR' />

    <div className="spacerM mnone"></div>
    <div className="monly" style={{height:'48px',width:'100%'}}></div>
    
    {/* Intro and Pic */}
    <div className="row justify-content-center mx-4">
        <div className="row mx-auto bound" style={{width:'100%'}}>
            <div className="col-md nopad smallBound my-auto">

                <h4>Hi! I’m <b>Johnson Su</b> a Software Engineer & student at the University of Toronto :)</h4>

                <div style={{width:"100%", height:"18px"}}></div>

                <div className="row">
                    <span className='workIcon'></span>
                    <h6 style={{display:"inline-block",Bottom:"2px"}}>Prev SWE Intern @ <a href="https://www.rippling.com/" className="link" target="_blank"><span>Rippling</span></a> & <a href="https://addepar.com/" className="link" target="_blank"><span>Addepar</span></a></h6>
                </div>
                <div style={{width:"100%", height:"8px"}}></div>

                <div className="row">
                    <span class='hatIcon'></span>
                    <h6 style={{display:"inline-block"}}>Computer Science Specialist, 4rd Year</h6>
                </div>
                <div style={{width:"100%", height:"8px"}}></div>

                <div className="row">
                    <span class='mailIcon'></span>
                    <h6 style={{display:"inline-block"}}>Johnsonn.su@mail.utoronto.ca</h6>
                </div>
                <div style={{width:"100%", height:"8px"}}></div>

                <div className="row">
                    <span class='locationIcon'></span>
                    <h6 style={{display:"inline-block"}}>Toronto, Ontario, Canada</h6>
                </div>

            </div>
            <div className="col-md smallBound ml-auto">
                <div className="spacerM monly"></div>
                <img src={"/assets/me.png"} className="img-fluid cardImg nopad" style={{ height:'auto'}}/>
            </div>
        </div>
    </div>

    <div className="spacerM"></div>

    <div className="row align-items-center justify-content-center">

        <div className="row smallBound mx-4" style={{width:'100%'}}>
            <h6>
                I'm deeply interested in Big Data, FinTech, Machine Learning, and Product Management.
                And especially love taking on a <b>user-centric</b> approach to problem spaces and working with problem of <b>scale</b>!
                <br/><br/>
                In the past I've interned as a Software Engineer at <a href="https://addepar.com/" className="link" target="_blank"><span>Addepar</span></a>, <a href="https://www.rippling.com/" className="link" target="_blank"><span>Rippling</span></a>, and <a href="https://www.indexexchange.com/" className="link" target="_blank"><span>Index Exchange</span></a>, Project Manager for the University of
                Toronto <a href="https://imedia.mie.utoronto.ca/" className="link" target="_blank"><span>HCI Lab</span></a>
                , and a Web Developer at MediPaint
                through <a href="https://ventureforcanada.ca/" className="link" target="_blank"><span>Venture for Canada</span></a>.
                <br/><br/>
                I'm always open to working in new roles and industries and am <b>currently seeking New Grad Opportunities</b>!

            </h6>
        </div>
        <div className="spacerM monly"></div>
        <div className="row smallBound mx-4" style={{width:'100%'}}>
            <div className="row " style={{width:'100%'}}>
                <div className="col text-center">
                    <h2 className="purp">5</h2>
                    <div style={{width:"100%", height:"1px"}}></div>
                    <h6>Internships</h6>
                </div>
                <div className="col text-center">
                    <h2 className="purp">8</h2>
                    <div style={{width:"100%", height:"1px"}}></div>
                    <h6>Hackathons</h6>
                </div>
            </div>
            <div className="row" style={{width:'100%'}}>
            <div style={{width:"100%", height:"32px"}}></div>
                <div className="col text-center">
                    <h2 className="purp">10</h2>
                    <div style={{width:"100%", height:"1px"}}></div>
                    <h6>Awards</h6>
                </div>
                <div className="col text-center">
                    <h2 className="purp">4</h2>
                    <div style={{width:"100%", height:"1px"}}></div>
                    <h6>Projects</h6>
                </div>
            </div>
        </div>
    </div>

    <div className="spacerM"></div>
    <div className="row justify-content-center">
        <div className="row smallBound mx-4" style={{width:'100%'}}>
            <div className="col">
                <h5>Languages</h5>
                <div style={{width:"100%", height:"12px"}}></div>
                {languages}
            </div>
        </div>
        <div className="spacerM monly"></div>
        <div className="row smallBound mx-4" style={{width:'100%'}}>
            <h5>Tools & Frameworks</h5>
            <div style={{width:"100%", height:"12px"}}></div>
            {tools}
        </div>
    </div>


    <div className="spacerL"></div>

    <Bottom />

    </>
  );
}

export default TLDR;